jQuery(function ($) {
	
	/*------------------------------ Page Scrolling ----------------------*/

	if( $(".page-scroll").length > 0 ) {
		$('.page-scroll a').bind('click', function(event) {
			var $anchor = $(this);
			$('html, body').stop().animate({
				scrollTop: $($anchor.attr('href')).offset().top
			}, 1500, 'easeInOutExpo');
			event.preventDefault();
		});
	}
	
	/*------------------------------ Tooltips ----------------------*/

	if( $('.tooltips').length > 0 ) {
		$('.tooltips').tooltipster({
			animation: 'grow',
			theme: 'tooltipster-punk'
		});
	}

	/*------------------------------ Bootstrap Carousel ----------------------*/

	if( $( '#myCarousel' ).length > 0 ) {
		$('#myCarousel').carousel({
			interval: 18000, //changes the speed
			pause: "false"
		});
	}

	//Bootstrap Carousel Progressbar
	
	$("#progressbar").progressbar({
		value: 1
	});
	$("#progressbar > .ui-progressbar-value").animate({
		width: "100%"
	}, 18000);
	
	$('#myCarousel').bind('slid.bs.carousel', function (e) {		
			$("#progressbar > .ui-progressbar-value").finish();
			$("#progressbar > .ui-progressbar-value").animate({
			width: "0%"
			}, 0);
			$("#progressbar > .ui-progressbar-value").animate({
			width: "100%"
			}, 18000);				
	});

	/*------------------------------ OWL Carousel -----------------*/

	if( $("#owl-man-family").length > 0 ) {
		$("#owl-man-family").owlCarousel({
			items : 2,
			lazyLoad : true
		});
	}
	
	if( $("#owl-woman-family").length > 0 ) {
		$("#owl-woman-family").owlCarousel({
			items : 2,
			lazyLoad : true
		});
	}
	
	if( $("#owl-moments").length > 0 ) {
		$("#owl-moments").owlCarousel({
			items : 4,
			pagination : false,
			autoPlay : true,
			lazyLoad : true
		});
	}
	
	if( $("#owl-common").length > 0 ) {
		$("#owl-common").owlCarousel({
			items : 3,
			lazyLoad : true
		});
	}
	
	if( $("#owl-blog-post-gallery").length > 0 ) {
		$("#owl-blog-post-gallery").owlCarousel({
			singleItem:true,
			autoPlay : true,
			lazyLoad : true
		});
	}
	
	/*------------------------------ Sticky Navigation -----------------*/

	$(".topbar-nav").sticky({topSpacing:0});
	
	/*------------------------------ Magnific POP -----------------*/
	if( $(".popup-cf-stream").length > 0 ) {
		$('.popup-cf-stream').magnificPopup({
			type: 'iframe',
			fixedContentPos: true,
			fixedBgPos: true
		});
	}

	/*------------------------------ Waypoint Counting -----------------*/

	if( $("#startcounting").length > 0 ) {
		$('#startcounting').waypoint(function() {
			$('.counts').countTo();
			$('#startcounting').waypoint('disable');
		});
	}
	
	/*------------------------------ WOW Script ----------------------*/

	new WOW().init();

	/*------------------------------ Count Up ----------------------*/
	if( $( "#afterWeddingTime" ).length > 0 ) {
		setInterval(function() {
			var aw_timespan = countdown(new Date( "February 18, 2018 19:15:00" ), new Date());
			var aw_div = document.getElementById('afterWeddingTime');
			aw_div.innerHTML = "<div><span>Years</span>" + aw_timespan.years + "</div>" + "<div><span>Months</span>" + aw_timespan.months + "</div>" + "<div><span>Days</span>" + aw_timespan.days + "</div>" + "<div><span>Hours</span>" + aw_timespan.hours + "</div>" + "<div><span>Minutes</span>" + aw_timespan.minutes + "</div>" + "<div><span>Seconds</span>" + aw_timespan.seconds + "</div>"
		}, 1000);
	}

	/*------------------------------ Collapse the navbar on scroll ----------------------*/

	$(window).scroll(function() {
		if ($(".navbar").offset().top > 50) {
			$(".navbar-fixed-top").addClass("top-nav-collapse");
		} else {
			$(".navbar-fixed-top").removeClass("top-nav-collapse");
		}
	});

	/*------------------------------ Background Video ----------------------*/
	if( $( "#bgvideo" ).length > 0 ) {
		document.getElementById("bgvideo").width=document.body.offsetWidth;
	}

	/*Show back to top button when needed*/
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$('#back-to-top').fadeIn(1000);
		} else {
			$('#back-to-top').fadeOut(1000);
		}
	});
	
	/*Click event to scroll to top*/
	$('#back-to-top').click(function(){
		$('html, body').animate({scrollTop : 0},800);
		return false;
	});
});

// Footer Year
const d = new Date();
const curr_year = d.getFullYear();
document.getElementById('curr_year').innerText = curr_year;
